<template>
  <div>
    <div class="container-fluid px-0 py-2">
      <div class="topo-itens">
        <div>
          <router-link :to="{ name: 'KitProductV2' }">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-arrow-left"
              style="opacity: 0.5"
            >
              <line x1="19" y1="12" x2="5" y2="12"></line>
              <polyline points="12 19 5 12 12 5"></polyline>
            </svg>
          </router-link>
        </div>
        <div>
          <h3 style="font-weight: 600">
            Valor da personalização:
            <strong>R$ {{ formatMoney(total) }}</strong>
          </h3>
        </div>
      </div>
      <div>
        <div class="">
          <div class="">
            <div
              class="w-100 aos-init"
              data-aos="fade"
              data-aos-delay="300"
              style="border-top: 1px #e9e9e9 solid"
            >
              <div class="grid-item" v-if="unity">
                <div class="unidade-item">
                  <div class="unidade-">
                    <img
                      :src="enterprise.image"
                      alt=""
                      style="max-width: 150px; margin: 10px auto"
                    />
                    <img
                      :src="unity.tipology ? unity.tipology.image : null"
                      style="max-width: 100%"
                      alt=""
                      class="img-tipology"
                    />

                    <div style="min-width: 100%">
                      <div class="itens-dado">
                        <div class="item-dado">
                          <span>TIPO</span>
                          <p>
                            {{ unity.tipology ? unity.tipology.name : null }}
                          </p>
                        </div>
                        <div class="item-dado">
                          <span>METRAGEM</span>
                          <p>
                            {{ enterprise ? enterprise.area : null }}
                          </p>
                        </div>
                      </div>

                      <span class="unidade-number"
                        >UNIDADE {{ unity.number }}</span
                      >
                      <p class="item-descricao">
                        {{ unity.tipology ? unity.tipology.description : null }}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  class="conteudo-item"
                  v-show="!loading"
                  data-anima="bottom"
                >
                  <div class="title-legenda">
                    KIT PREMIUM {{ enterprise.name || "" }}
                  </div>

                  <div class="col-12">
                    <div class="top-header">
                      <img
                        :src="enterprise.image"
                        alt=""
                        style="max-height: 30px"
                      />

                      <div style="text-align: right">
                        <a @click.prevent="goCheckout">
                          <button
                            type="submit"
                            class="btn btn-info font-14 font-b-5"
                            style="background-color: var(--color); width: 250px"
                          >
                            CONTINUAR
                          </button>
                        </a>
                      </div>
                    </div>

                    <div v-if="kitsList.length">
                      <BaseShopMenu
                        class="mt-4"
                        :kits="kitsList"
                        :kit="kit"
                        @clicked="fetchKit"
                        :order="order"
                        :new_style="true"
                        @addKartMenu="addKartMenu"
                      />
                    </div>                   
                  </div>
                </div>
                <div
                  class="conteudo-item"
                  style="display: flex; margin-top: 50px"
                  data-anima="top"
                  v-if="loading"
                >
                  <div
                    class="col-12 cancela-col-padding"
                    style="text-align: center"
                  >
                    <div class="loading">
                      <b-spinner label="Loading..."></b-spinner>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--  -->
        </div>
      </div>
    </div>

    <!-- footer kit -->
    <!-- <div
      class="col-12 px-4 d-flex align-items-center"
      style="
        position: fixed;
        bottom: 0;
        background: #212121;
        height: 80px;
        z-index: 99;
      "
    >
      <div class="d-auto mx-auto align-self-center">
        <div class="col-12">
          <div class="row">
            <div
              class="col-12 col-md-12 col-lg-auto align-self-center text-center"
            >
              <p class="font-b-4 font-13 text-white mb-0">
                TOTAL
                <span
                  class="font-30 fontm-15 font-b-5 mb-0"
                  style="color: #ffffff"
                  >R$ {{ formatMoney(totalKart) }}</span
                >
              </p>
            </div>
            <div
              class="col-12 col-md-12 col-lg-auto align-self-center text-center px-0"
            >
              <a
                data-bs-toggle="modal"
                data-bs-target="#right-modal-kit"
                href="#right-modal-kit"
                class="mr-2"
              >
                <button
                  type="submit"
                  class="btn btn-info font-14 font-b-5"
                  style="background-color: #ffffff; color: #212121"
                >
                  VER CARRINHO
                </button>
              </a>
              <a
                data-bs-toggle="modal"
                data-bs-target="#right-modal-kit"
                href="#right-modal-kit"
              >
                <button
                  type="submit"
                  class="btn btn-info font-14 font-b-5"
                  style="background-color: var(--color)"
                >
                  ENVIAR PEDIDO
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <!-- <Checkout :order="order" @updateTotal="updateTotal" /> -->

    <div
      id="modal-add-products"
      size="xl"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl modal-right">
        <div class="modal-content">
          <div class="modal-header border-0 hidde">
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-hidden="true"
            >
              <i class="ti-close font-20"></i>
            </button>
          </div>
          <div class="modal-body">
            <div id="produtos-lista" class="" v-show="kit.name">
              <div class="row mt-5">
                <div class="col-11 mx-auto px-2">
                  <!-- <h2 style="color: var(--color); font-weight: 600">
                    Produtos
                  </h2> -->
                  <div class="col-12">
                    <div class="row">
                      <div class="col-12">
                        <div class="row">
                          <div
                            class="col-12 col-md-6 col-lg-6 pb-3 pb-sm-4 align-self-end"
                          >
                            <h3
                              class="font-b-6 mb-2 mt-2"
                              style="color: var(--color)"
                              :style="
                                !kit.gallery.images.length
                                  ? 'margin-top: 20px !important'
                                  : ''
                              "
                            >
                              {{ kit.name }}
                            </h3>
                            <p class="mb-4">{{ kit.description }}</p>
                            <!-- <p class="mb-1">VALOR TOTAL DO KIT</p>
                            <h2 style="color: var(--color); font-weight: 600">
                              {{ formatMoney(kit.price) }}
                            </h2> -->
                            <div class="w-100 pt-2">
                              <button
                                type="submit"
                                class="btn btn-info"
                                style="background-color: var(--color)"
                                @click="addKart"
                              >
                                <b>ADICIONAR</b> AO CARRINHO
                              </button>
                            </div>
                          </div>
                          <div class="col-12 col-md-6 col-lg-6 py-2 py-sm-2">
                            <div class="w-100 pb-3">
                              <div
                                id="carouselProdutosAdd"
                                class="carousel slide"
                                data-bs-ride="carousel"
                                v-if="kit.gallery.images.length"
                              >
                                <ol class="carousel-indicators">
                                  <li
                                    data-bs-target="#carouselProdutosAdd"
                                    :class="{ active: index == 0 }"
                                    :data-bs-slide-to="index"
                                    v-for="(item, index) in kit.gallery.images"
                                    :key="item.id"
                                  ></li>
                                </ol>
                                <div class="carousel-inner">
                                  <div
                                    class="carousel-item text-center"
                                    :class="{ active: index == 0 }"
                                    v-for="(item, index) in kit.gallery.images"
                                    :key="item.id"
                                  >
                                    <img
                                      class="img-fluid"
                                      :src="item.path"
                                      alt="First slide"
                                      style="
                                        border-radius: 12px;
                                        max-height: 330px;
                                      "
                                    />
                                    <div class="carousel-caption text-left">
                                      <div
                                        class="d-auto"
                                        style="
                                          border-bottom: 2px var(--color) solid;
                                          margin-bottom: 20px;
                                          padding-left: 0px;
                                        "
                                      >
                                        <h3 class="text-white font-14">
                                          {{ item.subtitle }}
                                        </h3>
                                      </div>
                                      <!-- <p class="text-white d-none">Apartamento com 01 suíte, 1quarto e lavabo</p> -->
                                    </div>
                                  </div>
                                </div>
                                <a
                                  class="carousel-control-prev"
                                  href="#carouselProdutosAdd"
                                  role="button"
                                  data-bs-slide="prev"
                                >
                                  <span
                                    class="icon-arrow-left font-28"
                                    aria-hidden="true"
                                    style="
                                      color: white;
                                      text-shadow: #666 0.1em 0.1em 0.2em;
                                    "
                                  ></span>
                                  <span class="sr-only">Previous</span>
                                </a>
                                <a
                                  class="carousel-control-next"
                                  href="#carouselProdutosAdd"
                                  role="button"
                                  data-bs-slide="next"
                                >
                                  <span
                                    class="icon-arrow-right font-28"
                                    aria-hidden="true"
                                    style="
                                      color: white;
                                      text-shadow: #666 0.1em 0.1em 0.2em;
                                    "
                                  ></span>
                                  <span class="sr-only">Next</span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- GRADE -->
                      <template
                        v-if="kit.exhibition == 'GRADE' || !kit.exhibition"
                      >
                        <div
                          class="col-12"
                          v-for="(item, key) in kit.products"
                          :key="key"
                        >
                          <div class="col-12 col-md-4 col-lg-12">
                            <h3
                              class="font-b-1 mb-2 mt-2"
                              style="color: var(--color)"
                            >
                              |
                              <span
                                class="mb-0 font-b-3"
                                style="color: #7e7e7e"
                                >{{ key }}</span
                              >
                            </h3>
                            <hr />
                          </div>
                          <div class="row">
                            <div
                              class="col-12 col-md-4 col-lg-3 p-dash-cliente-master pb-4"
                              v-for="product in item"
                              :key="product.id"
                            >
                              <div class="card bg-white mb-0 h-100">
                                <div class="card-body px-3 pt-4 pb-1">
                                  <div
                                    class="px-2 pb-2 text-center d-image-product align-items-center d-flex text-center"
                                  >
                                    <img
                                      :src="product.product.image"
                                      class="img-fluid mx-auto"
                                      alt="Qsuíte"
                                      style="max-height: 115px"
                                    />
                                  </div>
                                  <div class="">
                                    <h2 class="font-15 font-b-6 mb-1 pt-2">
                                      {{ product.product.quantity }}
                                      {{ product.product.name }}
                                    </h2>
                                    <p class="font-11">
                                      Referência:{{ product.product.reference }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>

                      <!-- LIST -->
                      <template v-if="kit.exhibition == 'LIST'">
                        <div
                          class="col-12"
                          v-for="(item, key) in kit.products"
                          :key="key"
                        >
                          <div class="col-12 col-md-4 col-lg-12">
                            <h3
                              class="font-b-1 mb-2 mt-2"
                              style="color: var(--color)"
                            >
                              |
                              <span
                                class="mb-0 font-b-3"
                                style="color: #7e7e7e"
                                >{{ key }}</span
                              >
                            </h3>
                            <hr />
                          </div>
                          <div class="col-12 py-2 mb-2 px-0 line-box-list">
                            <div class="col-12">
                              <div class="row">
                                <div
                                  class="col-8 col-md-8 col-lg-8 p-0 align-self-center"
                                >
                                  <div class="col-12">
                                    <div class="row">
                                      <div
                                        class="col-3 col-md-auto col-lg-auto align-self-center text-center"
                                      >
                                        <h2 class="font-11 font-b-6 pt-2 pl-2">
                                          QTD
                                        </h2>
                                      </div>
                                      <div
                                        class="col-6 col-md-7 col-lg-8 text-left align-self-center p-0 text-center"
                                      >
                                        <p class="mb-0 font-11 font-b-6">
                                          TIPO
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="col-4 col-md-4 col-lg-4 p-0 align-self-center"
                                >
                                  <div class="col-12">
                                    <div class="row">
                                      <div
                                        class="col-12 col-md-6 col-lg-6 align-self-center p-0"
                                      >
                                        <p
                                          class="mb-0 font-b-6 font-11 d-none"
                                          style="color: #ff9300"
                                        >
                                          VALOR UNITÁRIO
                                        </p>
                                      </div>
                                      <div
                                        class="col-12 col-md-6 col-lg-6 align-self-center p-0"
                                      >
                                        <p
                                          class="mb-0 font-b-6 font-11 d-none"
                                          style="color: #212121"
                                        >
                                          VALOR TOTAL
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            class="col-12 p-dash-cliente-master pb-2"
                            v-for="product in item"
                            :key="product.id"
                            v-show="kit.exhibition == 'LIST'"
                          >
                            <div class="card line-box-list mb-0 h-100">
                              <div class="pb-2">
                                <div class="col-12">
                                  <div class="row">
                                    <div
                                      class="col-9 col-md-8 col-lg-8 p-0 align-self-center"
                                    >
                                      <div class="col-12">
                                        <div class="row">
                                          <div
                                            class="col-3 col-md-auto align-self-center"
                                          >
                                            <h2 class="font-14 font-b-6 pt-2">
                                              {{ product.product.quantity }}
                                            </h2>
                                          </div>
                                          <div
                                            class="col-6 col-md-7 col-lg-9 text-left align-self-center pl-0 pr-auto"
                                          >
                                            <p class="mb-0 font-13">
                                              {{ product.product.name }}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      class="col-3 col-md-4 col-lg-4 p-0 align-self-center"
                                    >
                                      <div class="col-12">
                                        <div class="row">
                                          <div
                                            class="col-12 col-md-6 col-lg-6 align-self-center p-0"
                                          >
                                            <p
                                              class="mb-0 font-b-6 font-14"
                                              style="color: #ff9300"
                                            ></p>
                                          </div>
                                          <div
                                            class="col-12 col-md-6 col-lg-6 align-self-center p-0"
                                          >
                                            <p
                                              class="mb-0 font-b-6 font-14"
                                              style="color: #212121"
                                            ></p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                      <!-- FIM LIST -->

                      <div class="col-12">
                        <div class="row">
                          <div
                            class="col-12 col-md-6 col-lg-8 pb-2 order-2 order-sm-1"
                          >
                            <div class="w-100 text-left text-sm-left">
                              <button
                                type="submit"
                                class="btn btn-info"
                                style="background-color: var(--color)"
                                @click="addKart"
                              >
                                <b>ADICIONAR</b> AO CARRINHO
                              </button>
                            </div>
                          </div>
                          <div
                            class="col-12 col-md-6 col-lg-auto pb-2 d-auto ml-auto order-1 order-sm-2"
                          >
                            <!-- <div
                              class="d-auto ml-auto w-100 text-left text-sm-right"
                            >
                              <h2 class="font-15 font-b-3 mb-0 pt-2">
                                VALOR TOTAL DO KIT
                              </h2>
                              <p
                                class="font-b-7 font-40 mb-1"
                                style="color: var(--color)"
                              >
                                R$ {{ formatMoney(kit.price) }}
                              </p>
                            </div> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Menu mobile -->
    <div
      id="menu-product"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-lg modal-right h-100 d-flex justify-content-start"
        style="border-radius: 0 0 0 18px !important"
      >
        <div class="modal-content" style="border-radius: 0 0 0 18px !important">
          <div class="modal-header border-0">
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-hidden="true"
            >
              <i class="ti-close font-20"></i>
            </button>
          </div>
          <div class="modal-body">
            <div class="h-100" style="min-width: 350px">
              <div class="col-12">
                <BaseShopMenu
                  :kits="kitsList"
                  :kit="kit"
                  @clicked="fetchKit"
                  :order="order"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import KitService from "@/services/resources/KitService";
import Checkout from "@/components/Kit/Checkout.vue";
import Money from "@/mixins/money";
const service = KitService.build();

export default {
  mixins: [Money],
  components: {
    Checkout,
  },
  props: {
    itens: {
      type: Array,
    },
    enterprise_id: {
      type: Number,
    },
    unity_id: {
      type: Number,
    },
    tipology_id: {
      type: Number,
    },
  },
  data() {
    return {
      loading: false,
      kit: {
        name: null,
        description: null,
        exhibition: null,
        price: 0,
        images: [],
        products: [],
        enterprise: {
          image: null,
        },
        gallery: {
          images: [],
        },
      },
      kits: [],
      totalKart: 0,
      order: {
        user_id: 0,
        unity_id: null,
        enterprise_id: null,
        tipology_id: null,
        itens: [],
        kits: [],
      },
      unity: null,
    };
  },
  computed: {
    user() {
      return this.$store.getters.getProfile;
    },
    total() {
      let sum = 0;

      if (this.order.kits) {
        this.order.kits.forEach((item) => {
          sum += item.price;
        });
      }

      if (this.order.itens) {
        this.order.itens.forEach((item) => {
          sum += item.custom_item.price;
        });
      }

      return sum;
    },
    isShow() {
      return (
        this.kits.length > 0 &&
        this.kits[0].id != this.kit.id &&
        this.order.kits.length > 0
      );
    },
    kitsList() {
      return this.kits
        .filter((elem) => {
          if (elem.is_decoration) {
            if (this.order.kits.length > 0) {
              for (let i = 0; i < this.order.kits.length; i++) {
                if (this.order.kits[i].decoration_kit_id == elem.id) {
                  return true;
                }
              }
              return false;
            }
          }

          return true;
        })
        .sort((a, b) => {
          return a.order < b.order ? -1 : a.order > b.order ? 1 : 0;
        });
    },
  },
  methods: {
    goCheckout() {
      let order = {
        itens: this.order.itens,
        unity_id: this.order.unity_id,
        enterprise_id: this.order.enterprise_id,
        tipology_id: this.order.tipology_id,
        unity: this.unity,
        enterprise: this.enterprise,
        kits: this.order.kits,
      };
      localStorage.order = JSON.stringify(order);
      this.$router.push({
        name: "CheckoutV2",
        params: order,
      });
    },

    addKartMenu(id) {
      this.kit = {
        name: null,
        description: null,
        exhibition: null,
        price: 0,
        images: [],
        products: [],
        enterprise: {
          image: null,
        },
        gallery: {
          images: [],
        },
      };
      this.fetchKit(id).then(() => {
        this.addKart();
      });
    },
    addKart() {
      let modal = bootstrap.Modal.getInstance(
        document.getElementById("modal-add-products")
      );
      if (modal) modal.hide();

      for (let i = 0; i < this.order.kits.length; i++) {
        if (this.order.kits[i].id == this.kit.id) {
          this.$bvToast.toast("Kit já consta no carrinho de compras", {
            title: "Carrinho de compras",
            autoHideDelay: 5000,
            variant: "danger",
          });
          return;
        }
      }
      if (this.kit.master_kit == 1) {
        for (let i = 0; i < this.order.kits.length; i++) {
          if (this.order.kits[i].master_kit == 1) {
            this.$bvToast.toast(
              "Você não pode adicionar dois kits master no mesmo carrinho.",
              {
                title: "Kit",
                autoHideDelay: 5000,
                variant: "danger",
              }
            );
            return;
          }
        }
      }
      if (
        this.kit.type == "MAIN" &&
        (this.kit.free_secondary == null || this.kit.free_secondary)
      ) {
        this.$bvToast.toast("Os kits combos foram liberados.", {
          title: "Kit",
          autoHideDelay: 5000,
          variant: "info",
        });
      }
      this.order.kits.push(this.kit);
      this.totalKart += this.kit.price;

      this.$bvToast.toast(`Kit adicionado ao carrinho`, {
        title: "Carrinho de compras",
        autoHideDelay: 5000,
        variant: "info",
      });
    },
    updateTotal(total) {
      this.totalKart = total > 0 ? total : 0;
    },
    fetchKits() {
      this.loading = true;
      let data = {
        enterprise_id: this.order.enterprise_id,
        tipology_id: this.order.tipology_id,
      };

      service
        .search(data)
        .then((resp) => {
          this.kits = resp;

         

          this.kits.forEach((element) => {
            if (element.type == "MAIN") {
              this.fetchKit(element.id);
              return;
            }
          });

           
          if (this.kits != null && this.kits.length > 0) {
            return 
          } else {
             this.goCheckout()
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setTimeout(() => {
            this.loading = false;
          }, 300);
        });
    },
    fetchKit(id) {
      return new Promise((resolve) => {
        let data = {
          id: id,
        };
        this.kit = {
          name: null,
          description: null,
          exhibition: null,
          price: 0,
          images: [],
          products: [],
          enterprise: {
            image: null,
          },
          gallery: {
            images: [],
          },
        };
        service
          .read(data)
          .then((resp) => {
            this.kit = resp;

            this.kit.products = this.kit.products.reduce(function (r, a) {
              let name = a.product.subdivision
                ? a.product.subdivision.name
                : "Todos";
              r[name] = r[name] || [];
              r[name].push(a);
              return r;
            }, Object.create(null));
            this.kit.products = Object.keys(this.kit.products)
              .sort((a, b) => {
                let first = this.kit.products[a][0].product.subdivision;
                let secound = this.kit.products[b][0].product.subdivision;
                return first && secound ? first.order - secound.order : 0;
              })
              .reduce((obj, key) => {
                obj[key] = this.kit.products[key];
                return obj;
              }, {});

            let modal = bootstrap.Modal.getInstance(
              document.getElementById("menu-product")
            );
            if (modal) modal.hide();
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            resolve(true);
          });
      });
    },
  },
  mounted() {
    (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:4932490,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
     })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    window.scrollTo({ top: 0, behavior: "smooth" });

    let order = JSON.parse(localStorage.order);
    this.order = {
      user_id: this.user.id,
      itens: order.itens,
      unity_id: order.unity_id,
      enterprise_id: order.enterprise_id,
      tipology_id: order.tipology_id,
      kits: order.kits || [],
    };

    this.unity = order.unity;
    this.enterprise = order.enterprise;
    this.fetchKits();

    if (document.querySelector(".row.px-2"))
      document.querySelector(".row.px-2").classList.remove("row", "px-2");
  },
};
</script>

<style scoped>
body {
  background: #fff !important;
}
</style>
<style scoped>
#modal-add-products .modal-dialog {
  width: 80%;
  min-height: 100vh;
}
.grid-item {
  display: grid;
  grid-template-columns: 300px 1fr;
  margin: 0 auto;
}
.unidade-item {
  background: rgba(244, 244, 246, 0.45);
  padding: 50px;
  min-height: 100vh;
}
.conteudo-item {
  background: #fff;
  padding: 50px;
  min-width: 1050px;
  width: 100%;
}
.itens-dado {
  display: flex;
  gap: 20px;
  border-bottom: 1px solid rgba(147, 147, 143, 0.3);
}
.item-dado span {
  color: #93938f;
  font-size: 11px;
  display: block;
  margin-bottom: 5px;
}
.item-dado p {
  color: rgba(46, 46, 46, 0.7);
  font-size: 16px;
}
.item-descricao {
  margin-top: 15px;
  color: rgba(46, 46, 46, 0.7);
  font-size: 14px;
}
.unidade- {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.unidade-number {
  display: block;
  margin-top: 15px;
  font-weight: 600;
  font-size: 14px;
  color: var(--color);
}
.grid-conteudo {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
}
.top-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
}
.topo-itens {
  background: rgba(244, 244, 246, 0.5);
  padding: 20px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5px;
  border-bottom: 1px solid #f4f4f6;
}
.topo-itens a,
.topo-itens h3 {
  margin: 0;
  color: #797979;
  margin-right: 10px;
}
.topo-itens h3 {
  font-size: 16px;
  font-weight: normal !important;
}
.title-legenda {
  padding: 15px;
  text-align: center;
  color: var(--color);
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  background: rgba(244, 244, 246, 0.5);
  margin-bottom: 10px;
  border-radius: 16px;
}
.btn {
  height: 60px;
  font-weight: 600;
  text-transform: uppercase;
}
@media screen and (max-width: 768px) {
  .grid-item,
  .grid-conteudo {
    grid-template-columns: 1fr;
  }
  .unidade-item,
  .conteudo-item {
    padding: 20px;
  }
  .unidade- img:first-child {
    margin: 0 auto !important;
  }
  .unidade- .img-tipology,
  .img-stand {
    max-width: 100% !important;
    height: 200px;
    object-fit: cover;
  }
  .text-mobile {
    text-align: left !important;
    padding-left: 25px;
    margin: 10px 0;
  }
  .conteudo-item {
    min-width: 100%;
  }
  .top-header {
    flex-direction: column;
    align-items: center;
  }
  .top-header img {
    margin: 20px auto 30px;
  }
  .top-header div {
    text-align: center !important;
  }
  .topo-itens {
    padding: 20px;
    gap: 15px;
  }
  .topo-itens h3 {
    font-size: 14px;
  }
  form select,
  form button {
    height: 55px;
    font-size: 13px;
  }
}
</style>